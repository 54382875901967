﻿// A class that makes it easy to export any ag-grid as a formatted CSV or PDF file
// How to use ExportGrid.exportToCSV(gridOptions, fileName, callback) or ExportGrid.exportToPDF(gridOptions, fileName, callback)
// Today's date is automatically prefixed to the file name
// If gridOptions.api is undefined, an error message is returned

// For date or datetime to be rendered correctly, the 'type' property inside the relevant columnDefs must be set to 'date' or 'datetime'
// For data that requires a renderer use the 'exportRenderer' property inside the relevant columnDefs - this is always called with the result of params.value, not params. 
// These are typically applied to columns that have cellRenderers. See the example below for inspections grid:

//{ headerName: 'Inspector', field: 'Negotiators', cellRenderer: negotiatorsCellRendererFunc, exportRenderer: negotiatorsExportRendererFunc },

//function negotiatorsExportRendererFunc(value) {
//    const names = params.value.map(el => el.Name).join(", ");
//    return names;
//}

// To set a column width for a PDF column, use the 'columnWidth' property inside the relevant columnDefs
// To format currency for a PDF column, use 'valueFormatter: currencyFormatter' inside the relevant columnDefs

// The final param is an optional callback that, if present, is run after the export of a PDF or CSV

let ExportGrid

try {
    ExportGrid = (() => {
        class ExportGrid {
            static #valueFormatter(params) {
                if (params.column.colDef.exportRenderer) {
                    return params.column.colDef.exportRenderer(params.value)
                } else if (params.column.colDef.type === "date") {
                    const formattedDate = params.value ? new Date(params.value).toLocaleDateString() : ''
                    return formattedDate;
                } else if (params.column.colDef.type === "datetime") {
                    const formattedDate = params.value ? moment(params.value).format("YYYY-MM-DDTHH:mm:ss") : ''
                    return formattedDate;
                } else {
                    return params.value;
                }
            }

            static exportToCSV(gridOptions, filename, callback, includeHidden) {
                if (!gridOptions.api) {
                    return showOVMessage("error", "Oops", "There is no data to export!");
                }

                // list of columns not to be included in export
                // typically added here are columns that are returned as an object(links for example).
                // otherwise file will include [Object object] instead of value
                var excludedExportColumns = [
                    "landlord_link",
                    "tenant_link",
                    "tenancy_link",
                    "vendor_link"
                ]

                // get all columns
                let columnKeys = gridOptions.columnApi.getAllColumns()

                // exclude columns from array
                columnKeys = columnKeys.filter(function (el) {
                    return !excludedExportColumns.includes(el.colId);
                });

                var filteredColumnKeys = null;

                // if includeHidden is passed through as true
                if (includeHidden === true) {
                    // only hide columns that have a blank header name
                    filteredColumnKeys = columnKeys.filter(
                        colKey => colKey.userProvidedColDef.headerName !== ''
                    )
                } else { // otherwise hide columnns that are blank or have been specifically flagged as hidden
                    filteredColumnKeys = columnKeys.filter(
                        colKey => colKey.userProvidedColDef.headerName !== '' &&
                        colKey.userProvidedColDef.hide !== true
                    )
                }

                // generate file
                columnKeys = filteredColumnKeys.map(col => col.colId)
                const date = new Date().toLocaleDateString()
                gridOptions.api.exportDataAsCsv({
                    processCellCallback: ExportGrid.#valueFormatter,
                    fileName: `${date}-${filename}.csv`,
                    columnKeys
                });

                typeof (callback) === 'function' && callback();
            }

            static #populatePrintPDFModal(columns) {
                let html = "";
                $('#modalSelectColsForPdf #populate-print-pdf-modal').empty();
                columns.forEach((column, index) => {

                    if (index % 2 === 0) {
                        html += `<div class="row">
                                <div class="col-sm-6 col-xs-12">
                                    <div class="form-group form-group-default input-group">
                                        <label class="inline cursor-pointer" for="acc-pdf-${column.headerName}">${column.headerName}</label>
                                        <div class="input-group-addon bg-transparent">
                                            <div class="switch yes_no">
                                                <input type="checkbox" id="acc-pdf-${column.headerName}" data-column="${column.headerName.split(' ').join('_')}" data-columnid="${column.id}">
                                                <label for="acc-pdf-${column.headerName}">
                                                    <span>Yes</span><span>No</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>`
                    } else {
                        html += `<div class="col-sm-6 col-xs-12">
                                    <div class="form-group form-group-default input-group">
                                        <label class="inline cursor-pointer" for="acc-pdf-${column.headerName}">${column.headerName}</label>
                                        <div class="input-group-addon bg-transparent">
                                            <div class="switch yes_no">
                                                <input type="checkbox" id="acc-pdf-${column.headerName}" data-column="${column.headerName.split(' ').join('_')}" data-columnid="${column.id}">
                                                <label for="acc-pdf-${column.headerName}">
                                                    <span>Yes</span><span>No</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>`
                    }
                });

                if (columns.length % 2 !== 0) {
                    html += '<div class="col-sm-6 col-xs-12"></div></div>'
                }

                $('#modalSelectColsForPdf #populate-print-pdf-modal').append(html)
            }

            static exportToPDF(gridOptions, filename, callback, includeHidden) {
                if (!gridOptions.api) {
                    return showOVMessage("error", "Oops", "There is no data to export!");
                }

                let columns = [];

                let data = [];
                let dataRows = [];

                const allColumns = gridOptions.columnApi.getAllColumns();

                allColumns.forEach(column => {

                    if (includeHidden === true) {
                        // only hide columns which have blank header name
                        if (column.userProvidedColDef.headerName !== "") {
                            columns.push({
                                headerName: column.colDef.headerName,
                                id: column.colDef.field,
                            })
                        }
                    } else {
                        if (column.userProvidedColDef.hide !== true && column.userProvidedColDef.headerName !== "") {
                            columns.push({
                                headerName: column.colDef.headerName,
                                id: column.colDef.field,
                            })
                        }
                    }
                });

                ExportGrid.#populatePrintPDFModal(columns);

                $('#modalSelectColsForPdf').modal('show');

                $('#acc-print-pdf').on('click', () => {

                    const selectedColumns = [];
                    const currencyIndexes = [];
                    const columnsStyles = {};

                    $('#modalSelectColsForPdf #populate-print-pdf-modal input[type="checkbox"]').each(function () {
                        if (this.checked) {
                            selectedColumns.push({
                                headerName: $(this).attr('data-column').split('_').join(' '),
                                id: $(this).attr('data-columnid'),
                            })
                        }
                    })

                    if (selectedColumns.length === 0) {
                        return showOVMessage("warning", "Oops!", "Please select at least one column to print");
                    }

                    gridOptions.api.forEachNodeAfterFilterAndSort(node => {
                        data.push(node.data);
                    });

                    data.forEach(row => {
                        const dataRow = [];
                        selectedColumns.forEach((c, i) => {
                            const column = allColumns.find(col => col.colId === c.id)

                            if (column.colDef.exportRenderer) {
                                const formattedData = column.colDef.exportRenderer(row[column.colId])
                                dataRow.push(formattedData);
                            } else if (column.colDef.type === 'date') {
                                const formattedDate = row[column.colId] ? new Date(row[column.colId]).toLocaleDateString() : ''
                                dataRow.push(formattedDate);
                            } else if (column.colDef.type === 'datetime') {
                                const formattedDate = row[column.colId] ? new Date(row[column.colId]).toLocaleString().replace(/,/g, '') : ''
                                dataRow.push(formattedDate);
                            } else if (column.colDef.valueFormatter?.name === 'currencyFormatter') {
                                currencyIndexes.push(i);
                                try {
                                    dataRow.push('£ ' + row[column.colId].toFixed(2));
                                } catch (err) {
                                    dataRow.push(row[[column.colId]]);
                                }
                            } else {
                                dataRow.push(row[[column.colId]]);
                            }

                            if (column.colDef.columnWidth) {
                                columnsStyles[i] = { columnWidth: column.colDef.columnWidth }
                            }
                        });
                        dataRows.push(dataRow);
                    });

                    const columnHeaders = selectedColumns.map(column => column.headerName)
                    const uniqueCurrencyIndexes = [...new Set(currencyIndexes)];
                    uniqueCurrencyIndexes.forEach(function (index) {
                        columnsStyles[index] = { halign: 'right' }
                    });

                    // 'l' for landscape
                    const doc = new jsPDF("l");
                    doc.autoTable(columnHeaders, dataRows, { styles: { fontSize: 9 }, columnStyles: columnsStyles });
                    const date = new Date().toLocaleDateString()
                    doc.save(`${date}-${filename}.pdf`);

                    $('#modalSelectColsForPdf').modal('hide');
                    ExportGrid.clearControls();
                    typeof (callback) === 'function' && callback();
                });
            }

            static selectAll() {
                $('#populate-print-pdf-modal input').prop('checked', true);
            }

            static clearAll() {
                $('#populate-print-pdf-modal input').prop('checked', false);
            }

            static clearControls() {
                $('#acc-print-pdf').unbind('click');
            }
        }

        return ExportGrid
    })()
} catch (err) {
    // if private methods not supported (e.g. Safari browser)
    ExportGrid = (() => {
        class ExportGrid {
            static valueFormatter(params) {
                if (params.column.colDef.exportRenderer) {
                    return params.column.colDef.exportRenderer(params.value)
                } else if (params.column.colDef.type === "date") {
                    const formattedDate = params.value ? new Date(params.value).toLocaleDateString() : ''
                    return formattedDate;
                } else if (params.column.colDef.type === "datetime") {
                    const formattedDate = params.value ? moment(params.value).format("YYYY-MM-DDTHH:mm:ss") : ''
                    return formattedDate;
                } else {
                    return params.value;
                }
            }

            static exportToCSV(gridOptions, filename, callback) {
                if (!gridOptions.api) {
                    return showOVMessage("error", "Oops", "There is no data to export!");
                }

                let columnKeys = gridOptions.columnApi.getAllColumns()
                const filteredColumnKeys = columnKeys.filter(colKey => colKey.userProvidedColDef.headerName !== '' && colKey.userProvidedColDef.hide !== true)
                columnKeys = filteredColumnKeys.map(col => col.colId)
                const date = new Date().toLocaleDateString()
                gridOptions.api.exportDataAsCsv({
                    processCellCallback: ExportGrid.valueFormatter,
                    fileName: `${date}-${filename}.csv`,
                    columnKeys
                });
                typeof (callback) === 'function' && callback();
            }

            static populatePrintPDFModal(columns) {
                let html = "";
                $('#modalSelectColsForPdf #populate-print-pdf-modal').empty();
                columns.forEach((column, index) => {

                    if (index % 2 === 0) {
                        html += `<div class="row">
                                <div class="col-sm-6 col-xs-12">
                                    <div class="form-group form-group-default input-group">
                                        <label class="inline cursor-pointer" for="acc-pdf-${column.headerName}">${column.headerName}</label>
                                        <div class="input-group-addon bg-transparent">
                                            <div class="switch yes_no">
                                                <input type="checkbox" id="acc-pdf-${column.headerName}" data-column="${column.headerName.split(' ').join('_')}" data-columnid="${column.id}">
                                                <label for="acc-pdf-${column.headerName}">
                                                    <span>Yes</span><span>No</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>`
                    } else {
                        html += `<div class="col-sm-6 col-xs-12">
                                    <div class="form-group form-group-default input-group">
                                        <label class="inline cursor-pointer" for="acc-pdf-${column.headerName}">${column.headerName}</label>
                                        <div class="input-group-addon bg-transparent">
                                            <div class="switch yes_no">
                                                <input type="checkbox" id="acc-pdf-${column.headerName}" data-column="${column.headerName.split(' ').join('_')}" data-columnid="${column.id}">
                                                <label for="acc-pdf-${column.headerName}">
                                                    <span>Yes</span><span>No</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>`
                    }
                });

                if (columns.length % 2 !== 0) {
                    html += '<div class="col-sm-6 col-xs-12"></div></div>'
                }

                $('#modalSelectColsForPdf #populate-print-pdf-modal').append(html)
            }

            static exportToPDF(gridOptions, filename, callback) {
                if (!gridOptions.api) {
                    return showOVMessage("error", "Oops", "There is no data to export!");
                }

                let columns = [];

                let data = [];
                let dataRows = [];

                const allColumns = gridOptions.columnApi.getAllColumns();

                allColumns.forEach(column => {
                    if (column.userProvidedColDef.hide !== true && column.userProvidedColDef.headerName !== "") {
                        columns.push({
                            headerName: column.colDef.headerName,
                            id: column.colDef.field,
                        })
                    }
                });

                ExportGrid.populatePrintPDFModal(columns);

                $('#modalSelectColsForPdf').modal('show');

                $('#acc-print-pdf').on('click', () => {

                    const selectedColumns = [];
                    const currencyIndexes = [];
                    const columnsStyles = {};

                    $('#modalSelectColsForPdf #populate-print-pdf-modal input[type="checkbox"]').each(function () {
                        if (this.checked) {
                            selectedColumns.push({
                                headerName: $(this).attr('data-column').split('_').join(' '),
                                id: $(this).attr('data-columnid'),
                            })
                        }
                    })

                    if (selectedColumns.length === 0) {
                        return showOVMessage("warning", "Oops!", "Please select at least one column to print");
                    }

                    gridOptions.api.forEachNodeAfterFilterAndSort(node => {
                        data.push(node.data);
                    });

                    data.forEach(row => {
                        const dataRow = [];
                        selectedColumns.forEach((c, i) => {
                            const column = allColumns.find(col => col.colId === c.id)

                            if (column.colDef.exportRenderer) {
                                const formattedData = column.colDef.exportRenderer(row[column.colId])
                                dataRow.push(formattedData);
                            } else if (column.colDef.type === 'date') {
                                const formattedDate = row[column.colId] ? new Date(row[column.colId]).toLocaleDateString() : ''
                                dataRow.push(formattedDate);
                            } else if (column.colDef.type === 'datetime') {
                                const formattedDate = row[column.colId] ? new Date(row[column.colId]).toLocaleString().replace(/,/g, '') : ''
                                dataRow.push(formattedDate);
                            } else if (column.colDef.valueFormatter?.name === 'currencyFormatter') {
                                currencyIndexes.push(i);
                                try {
                                    dataRow.push('£ ' + row[column.colId].toFixed(2));
                                } catch (err) {
                                    dataRow.push(row[[column.colId]]);
                                }
                            } else {
                                dataRow.push(row[[column.colId]]);
                            }

                            if (column.colDef.columnWidth) {
                                columnsStyles[i] = { columnWidth: column.colDef.columnWidth }
                            }
                        });
                        dataRows.push(dataRow);
                    });

                    const columnHeaders = selectedColumns.map(column => column.headerName)
                    const uniqueCurrencyIndexes = [...new Set(currencyIndexes)];
                    uniqueCurrencyIndexes.forEach(function (index) {
                        columnsStyles[index] = { halign: 'right' }
                    });

                    // 'l' for landscape
                    const doc = new jsPDF("l");
                    doc.autoTable(columnHeaders, dataRows, { styles: { fontSize: 9 }, columnStyles: columnsStyles });
                    const date = new Date().toLocaleDateString()
                    doc.save(`${date}-${filename}.pdf`);

                    $('#modalSelectColsForPdf').modal('hide');
                    ExportGrid.clearControls();
                    typeof (callback) === 'function' && callback();
                });
            }

            static selectAll() {
                $('#populate-print-pdf-modal input').prop('checked', true);
            }

            static clearAll() {
                $('#populate-print-pdf-modal input').prop('checked', false);
            }

            static clearControls() {
                $('#acc-print-pdf').unbind('click');
            }
        }

        return ExportGrid
    })()
}

export default ExportGrid;
