﻿'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };
(function ($, window) {
    'use strict';
    /**
     * @param {Select DOM} ele
     */

    var getSelect = function getSelect(ele) {
        var selected = [];
        ele.each(function () {
            selected.push($(this).data('item'));
        });
        return { selected: selected, moveIt: ele };
    };

    var ComboSwitch = function ComboSwitch(ele, opt) {

        this.$element = $(ele);
        this.options = opt;
        this.available = opt.data.available ? opt.data.available : [];
        this.availableLength = opt.data.available.length;
        this.selected = opt.data.selected ? opt.data.selected : [];
        this.selectedLength = opt.data.selected.length;
        this.fields = opt.fields ? opt.fields : null;

        this.init();
        return this;
    };

    /**
     * 
     */
    ComboSwitch.DEFAULTS = {
        data: {
            available: [],
            selected: []
        },
        fields: null,
        buttons: [{ action: 'add', label: 'Add', side: 'available' }, { action: 'addAll', label: 'Add All', side: 'available' }, { action: 'remove', label: 'Remove', side: 'selected' }, { action: 'removeAll', label: 'Remove All', side: 'selected' }, { action: 'cancelAddOption', label: 'Cancel', side: 'addOption' }, { action: 'saveUpdate', label: 'Save Option', side: 'addOption' }],
        buttonClass: 'comboSwitch-btnAction',
        translations: {
            availableOptions: 'Options Available:',
            selectedOptions: 'Selected Options:',
            descAddNew: 'Please, fill the fields to add a custom option',
            inputPlaceholder: 'Search for an Option'
        },
        select: {
            size: 15
        },
        search: true,
        sort: false

        /**
         * init
         */
    }; ComboSwitch.prototype.init = function () {
        this.initSort();
        this.initHtml();
        this.initData();
        this.initActions();
    };

    ComboSwitch.prototype.initSort = function () {
        if (this.options.sort) {
            this.selected.map((item, index) => {
                item.sort = index
                return item
            });
            this.available.map((item, index) => {
                item.sort = (this.selectedLength + index)
                return item
            });
        }
    };

    ComboSwitch.prototype.initHtml = function () {
        var available = this.initSelect(),
            selected = this.initSelect(),
            buttonGroupAvailable = $('<div>').addClass('button-group-available'),
            buttonGroupSelected = $('<div>').addClass('button-group-selected'),
            buttonGroupAddNew = $('<div>').addClass('button-group-add'),
            containerSearch = $('<div>').addClass('search-combo-list'),
            searchList = $('<input>').attr('placeholder', this.options.translations.inputPlaceholder).addClass('no-search'),
            addList = $('<button>').attr('type', 'button').addClass('button-combo-add').html('<i class="material-icons">add_box</i> Add New Lookup').prop('disabled', true),
            wrapperSelects = $('<div>').addClass('wrapper-combo-list'),
            wrapperAddNew = $('<form>').addClass('wrapper-combo-new form'),
            that = this;

        if (this.options.search !== false) {
            containerSearch.append($(searchList.removeClass('no-search')));
        }
        if (this.options.fields) {
            containerSearch.append($(addList));
            this.$element.prepend(wrapperAddNew);
        }
        if (this.options.label !== false) {
            available.prepend($('<p>').text(this.options.translations.availableOptions));
            selected.prepend($('<p>').text(this.options.translations.selectedOptions));
        }
        this.$element.addClass('comboswitch');
        this.$element.prepend($(containerSearch));
        this.$element.append(wrapperSelects);
        wrapperSelects.append(available.addClass('available'));
        wrapperSelects.append(selected.addClass('selected'));

        $.each(this.options.buttons, function (i, v) {
            var button = $('<button>').attr('type', 'button');
            button.addClass(typeof v.className == 'undefined' ? that.options.buttonClass : v.className);
            button.addClass(v.action);
            button.append(typeof v.label == 'undefined' ? v.action : v.label);
            if (v.side === 'available') {
                button.appendTo(buttonGroupAvailable);
            } else if (v.side === 'selected') {
                button.appendTo(buttonGroupSelected);
            } else {
                button.appendTo(buttonGroupAddNew);
            }
        });
        available.append(buttonGroupAvailable);
        selected.append(buttonGroupSelected);
        wrapperAddNew.append(buttonGroupAddNew);
    };

    ComboSwitch.prototype.initSelect = function () {
        var container = $('<div>').addClass('select');

        $('<ul>').attr({ 'data-size': this.options.select.size }).appendTo(container);
        return container;
    };

    //IF NEDEED (JUST IF IS REALLY NECESSARY - CALL RELOAD METHOD AND THE PLUGIN WILL RESTART )
    ComboSwitch.prototype.initData = function () {
        var available = this.$element.find('.select.available ul'),
            selected = this.$element.find('.select.selected ul'),
            wrapperItems = this.$element.find('.wrapper-combo-new'),
            wrapperActions = this.$element.find('.button-group-add'),
            that = this;
        selected.empty();
        available.empty();
        this.available = this.options.data.available;
        this.selected = this.options.data.selected;

        $.each(this.available, function (i, v) {
            var option = $('<li>').data('item', v).attr('data-id', v.id).addClass('comboSwitich-option-item'),
                textLabel = $('<span>').addClass('text-label').text(v.label);

            option.append($(textLabel));
            if (v.editable) {
                var editList = $('<button>').attr('type', 'button').addClass('button-combo-edit').html('<i class="material-icons">edit</i>'),
                    removeList = $('<button>').attr('type', 'button').addClass('button-combo-remove').html('<i class="material-icons">delete</i>');

                option.append($(removeList));
                option.append($(editList));
            }
            available.append(option);
        });

        $.each(this.selected, function (i, v) {
            var option = $('<li>').data('item', v).attr('data-id', v.id).addClass('comboSwitich-option-item'),
                textLabel = $('<span>').addClass('text-label').text(v.label);

            option.append($(textLabel));
            if (v.editable) {
                var editList = $('<button>').attr('type', 'button').addClass('button-combo-edit').html('<i class="material-icons">edit</i>'),
                    removeList = $('<button>').attr('type', 'button').addClass('button-combo-remove').html('<i class="material-icons">delete</i>');

                option.append($(removeList));
                option.append($(editList));
            }
            selected.append(option);
        });
        if (this.options.search) {
            var search = this.$element.find(".search-combo-list input").val().toLowerCase();
            this.filterData(search);
        }
        if (this.options.fields) {
            var itemId = '<input type="hidden" name="comboSwitchID">',
                itemLabel = '<div class="col-xs-12 col-md-12">' +
                    '<div class="form-group form-group-default required">' +
                    '<label>Label Name</label>' +
                    '<input class="form-control" type="text" name="label" required>' +
                    '</div>' +
                    '</div>';

            wrapperItems.append(itemId);
            wrapperItems.append(itemLabel);

            $.each(this.fields, function (i, v) {
                if (v.field.toLowerCase() === 'input' && v.type.toLowerCase() === 'checkbox') {

                    var translations,
                        item;

                    if (that.hasOwnProperty(v, 'translations')) {
                        translations = { active: v.translations.active, inactive: v.translations.inactive };
                    } else {
                        translations = { active: 'Yes', inactive: 'No' };
                    }

                    item = '<div class="' + v.bootstrap + '">' +
                        '<div class="form-group form-group-default input-group">' +
                        '<label class="inline" for="' + v.name + '">' + v.label + '</label>' +
                        '<span class="input-group-addon bg-transparent">' +
                        '<div class="switch yes_no">' +
                        '<input type="checkbox" value="true" name="' + v.name + '" id="' + v.name + '">' +
                        '<label for="' + v.name + '">' +
                        '<span>' + translations.active + '</span><span>' + translations.inactive + '</span>' +
                        '</label>' +
                        '</div>' +
                        '</span>' +
                        '</div>' +
                        '</div>';
                    wrapperItems.append(item);
                }
                else if (v.field.toLowerCase() === 'input') {
                    var att = "";
                    if (that.hasOwnProperty(v, 'attributes')) {
                        for (let j = 0; j < v.attributes.length; j++) {
                            att = att + ' ' + v.attributes[j].name + '="' + v.attributes[j].value+'"';
                        }
                    }
                    var item = '<div class="' + v.bootstrap + '">' +
                        '<div class="form-group form-group-default required">' +
                        '<label>' + v.label + '</label>' +
                        '<input class="form-control' + (that.hasOwnProperty(v, 'class') ? ' ' + v.class : '') +'" type="' + v.type + '" name="' + v.name + '"' + (att.length > 0 ? att : "") + ' required>' +
                        '</div>' +
                        '</div>';
                    wrapperItems.append(item);
                }
                else if (v.field.toLowerCase() === 'select') {
                    if (v.name == 'prooftypeid') { //remove required class for document proof type
                        var optionsList = '';
                        $.each(v.options, function (i, option) {
                            optionsList += '<option value="' + option.value + '">' + option.text + '</option>';
                        });

                        var item = '<div class="' + v.bootstrap + '">  ' +
                            '<div class="form-group form-group-default form-group-default-select2">' +
                            '<label>' + v.label + '</label>' +
                            '<select class="full-width init-select2" name="' + v.name + '"  ' + v.type + '>' +
                            optionsList +
                            '</select>' +
                            '</div>' +
                            '</div>';
                        wrapperItems.append(item);
                    } else {
                        var optionsList = '';
                        $.each(v.options, function (i, option) {
                            optionsList += '<option value="' + option.value + '">' + option.text + '</option>';
                        });

                        var item = '<div class="' + v.bootstrap + '">  ' +
                            '<div class="form-group form-group-default form-group-default-select2 required">' +
                            '<label>' + v.label + '</label>' +
                            '<select class="full-width required init-select2" name="' + v.name + '"  ' + v.type + ' required>' +
                            optionsList +
                            '</select>' +
                            '</div>' +
                            '</div>';
                        wrapperItems.append(item);
                    }
                }
            });

            wrapperItems.append(wrapperActions);
            wrapperItems.prepend($('<p>').text(this.options.translations.descAddNew));
        }
        // INIT THIRD PARTY PLUGINS
        $('.init-select2').select2();
        new Sortable(available[0], {
            //group: 'shared',
            animation: 150,
            sort: false,
            multiDrag: true,
            ghostClass: 'ghost-class',
            onSelect: function (evt) {
                $(evt.item).addClass('select');
            },
            onDeselect: function (evt) {
                setTimeout(function () {
                    $(evt.item).removeClass('select');
                }, 200);
            },
            onEnd: function (evt) {
                that.trigger('onSort', evt);
            }
        });
        new Sortable(selected[0], {
            //group: 'shared',
            animation: 150,
            sort: this.options.sort,
            multiDrag: true,
            ghostClass: 'ghost-class',
            onSelect: function (evt) {
                $(evt.item).addClass('select');
            },
            onDeselect: function (evt) {
                setTimeout(function () {
                    $(evt.item).removeClass('select');
                }, 200);
            },
            onEnd: function (evt) {
                if (that.options.sort) {
                    var items = evt.to.children,
                        result = that.sortItems(items);
                    that.trigger('onSort', result);
                }
            },
        });
    };

    ComboSwitch.prototype.hasOwnProperty = function (object, value) {
        if (Object.prototype.hasOwnProperty.call(object, value)) {
            return true
        } else {
            return false
        }
    }

    ComboSwitch.prototype.validateForm = function () {
        var empty = this.$element.find('.wrapper-combo-new input[required]').filter(function () {
            return this.value == '';
        });

        if (empty.length === 0) {
            empty = this.$element.find('.wrapper-combo-new select[required]').filter(function () {
                return Number($(this).val()) === 0;
            });
        }

        if (empty.length) {
            showOVMessage('warning', 'Fields required', 'Please enter a value in the required fields');
            return false;
        } else {
            return true;
        }
    }

    ComboSwitch.prototype.filterData = function (search) {
        var that = this,
            optionsList = that.$element.find(".available ul li, .selected ul li"),
            filterData = that.available.concat(that.selected),
            filterResults = filterData
                .filter(function (item) {
                    return item.label.toLowerCase().includes(search)
                }).map(function (item) {
                    return item.id.toString()
                });
        if (filterResults.length) {
            optionsList.each(function () {
                if ($.inArray($(this).data('item').id.toString(), filterResults) !== -1) {
                    $(this).removeClass('combo-item-hidden');
                } else {
                    $(this).addClass('combo-item-hidden');
                }
            });
        }
    }

    ComboSwitch.prototype.sortItems = function (elements) {
        if (elements) {
            var result = [];
            $.each(elements, function (i, item) {
                var node = $(this).data('item');
                node.sort = i;
                result.push(node);
            });

            return result;
        }
    }

    ComboSwitch.prototype.moveItems = function (items, list) {
        var moveTo = this.$element.find('.' + list + ' ul');
        items.each(function () {
            $(this).appendTo(moveTo);
        });
    }

    ComboSwitch.prototype.initActions = function () {
        var el = this.$element;
        var that = this;

        this.$element.find(".search-combo-list input").on('keyup', function () {
            if (that.options.search) {
                var search = $(this).val().toLowerCase();
                that.filterData(search);
            }
        });

        this.$element.find(".search-combo-list .button-combo-add, .cancelAddOption").on('click', function () {
            if (that.options.fields) {
                el.find(".wrapper-combo-new").toggleClass('toggled-combo');
                el.find(".wrapper-combo-new")[0].reset();
            }
        });

        this.$element.find(".wrapper-combo-list .button-combo-edit").on('click', function () {

            if (that.options.fields) {
                el.find(".wrapper-combo-new").toggleClass('toggled-combo');
                var data = $(this).closest('li').data('item'),
                    elements = el.find(".wrapper-combo-new input, .wrapper-combo-new select, .wrapper-combo-new textarea");

                $.each(elements, function (i, item) {
                    if (item.type == 'select-one') {
                        $(item).val(data[item.name]).trigger('change');
                        //el.find('select[name="' + item.name + '"]').val(data[item.name]).trigger('change');
                    } else if (item.type == 'checkbox' || item.type == 'radio') {
                        el.find('input[name="' + item.name + '"]').prop('checked', data[item.name]);
                    } else if (item.type != 'checkbox' || item.type != 'radio') {
                        el.find('input[name="' + item.name + '"]').val(data[item.name]);
                    }
                });
                el.find('input[name="comboSwitchLabel"]').val(data.label);
                el.find('input[name="comboSwitchID"]').val(data.id);
            }
        });

        this.$element.find(".saveUpdate").on('click', function () {
            var elements = el.find(".wrapper-combo-new input:not([type=hidden]), .wrapper-combo-new select, .wrapper-combo-new textarea");

            var itemID = el.find('input[name="comboSwitchID"]').val(),
                foundAvailable = that.available.findIndex((x) => x.id == itemID),
                foundSelected = that.selected.findIndex((x) => x.id == itemID),
                isValidForm = that.validateForm();

            if (isValidForm) {
                if (foundAvailable != -1) {
                    $.each(elements, function (i, item) {
                        //console.log(item);
                        that.available[foundAvailable][item.name] = item.value === 'true' ? true : (item.value === 'false' ? false : item.value);
                    });
                    $('[data-id=' + itemID + '] .text-label').text(that.available[foundAvailable].label)
                    that.trigger('saveUpdate', that.available[foundAvailable]);
                } else if (foundSelected != -1) {
                    $.each(elements, function (i, item) {
                        that.selected[foundSelected][item.name] = item.value === 'true' ? true : (item.value === 'false' ? false : item.value);
                    });
                    el.find('[data-id=' + itemID + '] .text-label').text(that.selected[foundSelected].label)
                    that.trigger('saveUpdate', that.selected[foundSelected]);
                } else {
                    that.trigger('saveUpdate', elements);
                }
                el.find(".wrapper-combo-new").toggleClass('toggled-combo');
            }
        });

        this.$element.find(".button-combo-remove").on('click', function () {
            var itemID = $(this).closest("li").data('id'),
                filterData = that.available.concat(that.selected),
                filterResults = filterData.find(function (item) {
                    return item.id == itemID
                });

            that.trigger('onDelete', filterResults);

        });

        this.$element.find(".button-group-available .add").on('click', function () {
            var selectValue = getSelect(el.find(".select.available li.select"));
            $.merge(that.selected, selectValue);
            that.available = that.available.filter(function (v) {
                for (var i = 0, len = selectValue.length; i < len; i++) {
                    if (selectValue[i].id != v.id) {
                        continue;
                    }
                    return false;
                }
                return true;
            });

            that.moveItems(selectValue.moveIt, 'selected');
            that.trigger('add', selectValue.selected);
        });

        this.$element.find(".button-group-selected .remove").on('click', function () {
            var selectValue = getSelect(el.find(".select.selected li.select"));

            $.merge(that.available, selectValue);

            that.selected = that.selected.filter(function (v) {
                for (var i = 0, len = selectValue.length; i < len; i++) {
                    if (selectValue[i].id != v.id) {
                        continue;
                    }
                    return false;
                }
                return true;
            });
            
            //var number = document.getElementById("lookupType").value;
            var number = $('.lookupType:visible');
            if (number == 4) { //Property Room Typelookup
                var isUnassigned = selectValue.selected.findIndex(e => e.label == "Unassigned");
                if (isUnassigned != -1) {
                    showOVMessage('warning', 'Unassigned lookup', 'You can not remove the Unassigned lookup');
                } else {
                    var isInUse = selectValue.selected.findIndex(e => e.inuse == true);
                    if (isInUse != -1) {
                        window.swal({
                            title: "Are you sure you like to do this?",
                            text: "One or more of the lookups you wish to remove are currently in use. If you choose to remove these lookups, the rooms' type will be changed to 'Unassigned'. Are you sure you would like to do this?",
                            type: 'question',
                            showCancelButton: true,
                            confirmButtonText: 'Remove',
                            cancelButtonText: 'Cancel',
                            reverseButtons: true
                        }).then(function (result) {
                            if (result.value) {
                                that.moveItems(selectValue.moveIt, 'available');
                                that.trigger('remove', selectValue.selected);
                            }
                        });
                    } else {
                        that.moveItems(selectValue.moveIt, 'available');
                        that.trigger('remove', selectValue.selected);
                    }
                }
            } else {
                that.moveItems(selectValue.moveIt, 'available');
                that.trigger('remove', selectValue.selected);
            }
        });

        this.$element.find(".button-group-available .addAll").on('click', function () {
            var moveAll = el.find(".select.available li");
            var selectValue = that.available;
            $.merge(that.selected, selectValue);
            that.available = [];
            that.moveItems(moveAll, 'selected');
            that.trigger('add', selectValue);
        });

        this.$element.find(".button-group-selected .removeAll").on('click', function () {
            var moveAll = el.find(".select.selected li");
            var selectValue2 = getSelect(el.find(".select.selected li"));
            var selectValue = that.selected;
            $.merge(that.available, selectValue);
            that.selected = [];

            //var number = document.getElementById("lookupType").value;
            var number = $('.lookupType:visible');
            if (number == 4) { //Property Room Typelookup
                var isUnassigned = selectValue2.selected.findIndex(e => e.label == "Unassigned");
                if (isUnassigned != -1) {
                    showOVMessage('warning', 'Unassigned lookup', 'You can not remove the Unassigned lookup');
                } else {
                    var isInUse = selectValue2.selected.findIndex(e => e.inuse == true);
                    if (isInUse != -1) {
                        window.swal({
                            title: "Are you sure you like to do this?",
                            text: "One or more of the lookups you wish to remove are currently in use. If you choose to remove these lookups, the rooms' type will be changed to 'Unassigned'. Are you sure you would like to do this?",
                            type: 'question',
                            showCancelButton: true,
                            confirmButtonText: 'Remove',
                            cancelButtonText: 'Cancel',
                            reverseButtons: true
                        }).then(function (result) {
                            if (result.value) {
                                that.moveItems(moveAll, 'available');
                                that.trigger('remove', selectValue);
                            }
                        });
                    } else {
                        that.moveItems(moveAll, 'available');
                        that.trigger('remove', selectValue);
                    }
                }
            } else {
                that.moveItems(moveAll, 'available');
                that.trigger('remove', selectValue);
            }
        });
    };

    ComboSwitch.prototype.reloadData = function () {
        //console.log('refreshing data');
        var formAdd = this.$element.find('.wrapper-combo-new');
        $(formAdd).find('*').not('.button-group-add').empty();
        this.$element.find('.comboSwitich-option-item').remove();
        this.$element.find('.cancelAddOption').text('Cancel');
        this.$element.find('.saveUpdate').text('Save Option');
        this.$element.find('.button-combo-add, .search-combo-list input, .button-combo-edit, .cancelAddOption, .saveUpdate, .button-combo-remove, .button-group-available .add, .button-group-selected .remove, .button-group-available .addAll, .button-group-selected .removeAll').off('click hover keyup');
        this.initSort();
        this.initData();
        this.initActions();
    };
    /**
     * @param {string} name 
     */
    ComboSwitch.prototype.trigger = function (name) {
        var args = Array.prototype.slice.call(arguments, 1);
        name = 'comboswitch.' + name;

        this.$element.trigger($.Event(name), args);
    };

    ComboSwitch.prototype.getSelectedItems = function () {
        var items = this.$element.find(".select.selected li"),
            objResult = this.sortItems(items);
        return objResult;
    };

    var allowedMethods = ['getSelectedItems', 'reloadData'];
    // Plugin definition.
    $.fn.comboSwitch = function (option) {
        var value,
            args = Array.prototype.slice.call(arguments, 1);

        this.each(function () {
            var $this = $(this),
                obj = $this.data('comboswitch');

            if (obj && typeof option === 'string') {
                if ($.inArray(option, allowedMethods) < 0) {
                    throw new Error("Unknown method: " + option);
                }
                value = obj[option].apply(obj, args);
            }

            if ((typeof option === 'undefined' ? 'undefined' : _typeof(option)) === 'object') {
                var options = $.extend({}, ComboSwitch.DEFAULTS, option);
                $this.data('comboswitch', new ComboSwitch(this, options));
            }
        });

        return typeof value === 'undefined' ? this : value;
    };
})(jQuery, window);