var myTable = $('#tableOpenview').DataTable({
    //"scrollX": true,
    "colReorder": true,
    "fixedHeader": true,
    "sDom": "lrtip",
    "destroy": true,
    "scrollCollapse": true,
    "bFilter": true,
    "responsive": true,
    "bLengthChange": false,
    //"order": [[1, "asc"]],
    "aLengthMenu": [[5, 10, 15, 25, 50, -1], [5, 10, 15, 25, 50, "All"]],
    "oLanguage": {
        "sLengthMenu": '_MENU_',
        "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
    },
    "deferRender": true,
    "iDisplayLength": 5
});

$('#search-table').keyup(function () {
    myTable.search($(this).val()).draw();
})

$('#pageSize').on('change', function () {
    myTable.page.len($(this).val()).draw();
});

$('.showHideColumn').on('click', function (e) {
    e.preventDefault();
    var tableColumn = myTable.column($(this).attr('data-columnindex'));
    tableColumn.visible(!tableColumn.visible());
});
