/* ----- Layout ----- */
import './resources/layout/jquery-3.5.1' // required for BS5
import './resources/layout/moment.min'
import './resources/layout/calentim.min'
import './resources/layout/export-grid-class'
// import './resources/layout/promise';
import './resources/layout/openview-notification'
import './resources/layout/jquery-ui'
import './resources/layout/summernote'

import './resources/popper-v2.11.8.min' // required for BS5
// import './resources/layout/bootstrap'; // old
import './resources/bootstrap-frankenstein' // mix of BS3 and BS5 whilst we upgrade

import './resources/layout/pace.min'
import './resources/layout/jquery.scrollbar'
import './resources/layout/select2.full'
import './resources/layout/jquery.sieve'
import './resources/layout/jquery.inputmask.min'
import './resources/layout/bootstrap-tabcollapse'
import './resources/layout/classie'
import './resources/layout/jquery.actual.min'
import './resources/layout/sweetalert2.all.min'
import './resources/layout/Sortable.min.js'
import './resources/layout/mixitup.min'
import './resources/layout/mixitup-multifilter.min'
import './resources/layout/mixitup-pagination.min'
import './resources/layout/cleave.min'
import './resources/layout/nanobar'
import './resources/layout/jquery.validate.min'
import './resources/layout/jquery.validate.unobtrusive.min'

/* ----- Index ----- */
import './resources/index/loader'
import './resources/index/nouislider.min'
import './resources/index/wNumb'
import './resources/index/jquery.canvasjs.min'
import './resources/index/loading-bar.min'
import './resources/index/jspdf.min'
import './resources/index/jspdf.plugin.autotable'
import './resources/lightgallery-all.min'
import './resources/index/dropzone.min'
import './resources/index/download.min'
import './resources/index/comboSwitch'

/* ----- Records ----- */
import './resources/records/lightslider.min'

/* ----- Other ----- */
// import './resources/currencyFormatter';
import './resources/jquery-easing'
import './resources/ag-grid-community.min.noStyle'
import './resources/flatpickr'
// import './resources/webfont-loader'

/* ----- Edit Payment? AutoNumeric ----- */
// import './resources/autoNumeric/AutoNumeric';
// didn't compile

/* ----- Applicant Record ----- */
import './resources/jquery-shorten'

/* ----- jQuery Data Tables ----- */
import './resources/jquery-dataTables.min'
import './resources/jquery-dataTables.bootstrap.min'
import './resources/jquery-datatables.responsive'
import './resources/tables.js'

// Attach classes to dom elements
document.addEventListener('DOMContentLoaded', () => {

})

// JQuery test
$(() => {
  if (jQuery) {
    // console.log('jquery works')
  }
})

// jquery();
